import styled from '@emotion/styled';
import { Layout } from 'antd';
const { Sider } = Layout;

const SideBarViewStyled = {
  Sider: styled(Sider)`
    background-color: #150056 !important;
    .ant-menu {
      background-color: #150056 !important;
      .tab-active {
        position: absolute;
        right: -3px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        font-family: 'Fa solid 900', sans-serif;
        color: #e7edf3;
        font-size: 45px;
        font-weight: 400;
      }
      li.ant-menu-item {
        min-height: 64px;
        margin: 0px;
        &.ant-menu-item-selected {
          background-color: #ffffff33;
          position: relative;
          .tab-active {
            display: flex;
          }
        }
        &.user-info {
          min-height: 100%;
          height: 100%;
          .ant-menu-title-content {
            a {
              text-align: center;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              padding-top: 40px;
              padding-bottom: 10px;
              .img-user {
                width: 130px;
                height: 130px;
                margin-bottom: 10px !important;
                border-radius: 50%;
                text-align: center;
              }
              > h1 {
                font-family: 'Fira Sans', sans-serif;
                font-size: 30px;
                line-height: 1.2;
                color: #fff;
                font-weight: 600;
                white-space: normal !important;
              }
              > p {
                font-size: 16px;
                color: hsla(0, 0%, 100%, 0.64);
              }
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          .fa-icon {
            display: flex;
            margin-right: 10px;
            font-family: 'Fa 300', sans-serif;
            font-size: 24px;
            line-height: 1.2;
          }
          .anticon {
            font-size: 24px;
          }
        }
      }
    }
  `,
};

export default SideBarViewStyled;
