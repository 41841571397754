import { css } from '@emotion/react';

export const useStyleModal = (): {
  styleModal;
} => {
  const styleModal = css`
    .ant-modal-root {
      .ant-modal-mask {
        background-color: #fff;
        opacity: 0.75;
      }
      .ant-modal {
        .ant-modal-content {
          background-color: #150056;
          border-radius: 15px;
          .ant-modal-body {
            text-align: center;
            color: #ffffff;
            padding-left: 45px;
            padding-right: 45px;
            h2 {
              font-family: FiraSans;
              font-size: 32px;
              font-weight: 700;
              color: #ffffff;
              margin-bottom: 30px;
              margin-top: 20px;
            }
            p {
              font-size: 17px;
              font-weight: 400;
              margin-bottom: 24px;
            }
            .modal-spin {
              margin-top: -62px;
              &__wrapper {
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #e7edf3;
              }
            }
          }
        }
      }
    }
  `;
  return { styleModal };
};
