import { css, useTheme } from '@emotion/react';
import { useLocation } from '@reach/router';
import { useWeb3React } from '@web3-react/core';
import { navigate } from 'gatsby';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CookiesConsent from '@/components/cookie-consent';
import ModalAddMailing from '@/components/custom-modals/modal-add-mailling';
import ModalCreateLandingPage from '@/components/custom-modals/modal-create-landing-page';
import AddMailingButtonModal from '@/components/custom-modals/modal-add-mailing-list';
import ModalCheckout from '@/components/custom-modals/modal-checkout';
import ConfirmModal from '@/components/custom-modals/modal-confirm';
import CreditsModal from '@/components/custom-modals/modal-credit';
import ModalCreditBalance from '@/components/custom-modals/modal-credit-balance';
import HomeLimitModal from '@/components/custom-modals/modal-home-limit';
import LoadingModal from '@/components/custom-modals/modal-loading';
import MetamaskNotFoundModal from '@/components/custom-modals/modal-metamask-notfound';
import WarningThresholdLimitModal from '@/components/custom-modals/modal-onboarding/modal-warning-threshold-limit';
import { ModalPreviewLandingPage } from '@/components/custom-modals/modal-preview-landing-page';
import RedirectPastOrderModal from '@/components/custom-modals/modal-redirect-past-order';
import ModalSubscriptionPackage from '@/components/custom-modals/modal-subscription-package';
import ModalWebsite from '@/components/custom-modals/modal-website';
import ModalPurchasePremium from '@/components/custom-modals/modal-purchase-premium';
import { LoadingPage } from '@/components/loading/loadingBase';
import globalVariable from '@/config/env';

import useCloseModalOnPopstate from '@/hooks/useCloseModalOnPopstate';
import { useConnectWallet } from '@/hooks/useConnectWallet';
import useMobile from '@/hooks/useMobile';
import usePurchaseTemplate from '@/hooks/usePurchaseTemplate';

import AppAction from '@/reducers/app/action';
import { RootState } from '@/reducers/model';
import CampaignNftService from '@/services/campaign-nft';
import { GlobalModalCredits, PaymentModalType, StorageKeys } from '@/utils/enum';
import RoutePaths from '@/utils/routes';
import { switchNetwork } from '@/utils/utils';

import SimpleBarCore from 'simplebar-core';
import { SettingTabsKey, SmartActionTabsKey } from '@/models';
import ModalConfigureBrokerSubscription from '@/components/custom-modals/modal-configure-broker-subscription';
import SimpleBar from 'simplebar-react';
import NavMobile from './NavBar/NavMobile';
import SideBar from './SideBar';
import LayoutDashboardStyled from './LayoutDashboardStyled';
import AppButtonV2 from '@/components/AppButtonV2';
import useGetListCard from '@/hooks/useGetListCard';

interface LayoutProps {
  className?: string;
  staticLayout?: boolean;
  isNotShowSidebar?: boolean;
}

//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const ethereum = typeof window !== 'undefined' && window.ethereum;

const LayoutDashboard: React.FC<LayoutProps> = (props) => {
  // Stripe cards
  useGetListCard(true);

  // Purchase Template
  const { handleSubmit, buyManualCredit, errorPurchaseTemplate, minHome, userPaymentCard, setErrorPurchaseTemplate } =
    usePurchaseTemplate();

  // Props
  const { children, className, isNotShowSidebar } = props;

  // Theme
  const theme = useTheme();

  // Web3 React Hooks
  const { connectInjected, connectDeactivate } = useConnectWallet();
  const { active, chainId, account } = useWeb3React();

  // Redux State
  const visibleGlobal = useSelector((state: RootState) => state.app.visibleGlobalCredit);
  const visibleGlobalUnsubscribe = useSelector((state: RootState) => state.app.visibleGlobalUnsubscribe);
  const { mySub } = useSelector((state: RootState) => state.homeCredit);
  const isLoading = useSelector((state: RootState) => state.app.loading);
  // UserType
  // Local State
  const [isMetamaskNotFoundVisible, setIsMetamaskNotFoundModalVisible] = useState(false);
  const [isConnectingWalletModalVisible, setIsConnectingWalletModalVisible] = useState(false);
  const [isWrongNetworkModalVisible, setIsWrongNetworkModalVisible] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);

  // Other Hooks and Utils
  const isMobile = useMobile(theme.media.desktops);
  const isBrowser = typeof window !== 'undefined';

  // Ref
  const simpleBarRef = useRef<SimpleBarCore>(null);

  // Additional Hooks and Utils
  // useChatLoader();
  useCloseModalOnPopstate();

  // Router
  const location = useLocation();

  // Redux Dispatch
  const dispatch = useDispatch();

  const copyToClipBoard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(account as string);
    setIsAddressCopied(true);
  };

  const connectMetamaskSuccess = () => {
    setIsConnectingWalletModalVisible(false);
  };

  const connectMetamaskFail = () => {
    setIsConnectingWalletModalVisible(false);
  };

  const toggleMetamaskNotFoundModal = () => {
    setIsMetamaskNotFoundModalVisible(!isMetamaskNotFoundVisible);
  };

  const onConnectMetamask = () => {
    if (ethereum && ethereum.isMetaMask) {
      setIsConnectingWalletModalVisible(true);
      connectInjected(null, connectMetamaskSuccess, connectMetamaskFail);
    } else toggleMetamaskNotFoundModal();
  };

  const disconnectMetamask = async () => {
    if (location.pathname.includes(RoutePaths.MINT_CAMPAIGN_NFT)) {
      navigate(RoutePaths.CAMPAIGNS);
    } else if (location.pathname.includes(RoutePaths.CAMPAIGN_NFT_DETAIL)) navigate(RoutePaths.MY_NFT);
    connectDeactivate();
    localStorage.removeItem(StorageKeys.WALLET_CONNECTED);
    try {
      await CampaignNftService.disconnectWallet();
    } catch (error) {
      // Do nothing
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      simpleBarRef.current?.recalculate();
    }, 0);
  }, []);

  useEffect(() => {
    if (isAddressCopied) setTimeout(() => setIsAddressCopied(false), 5000);
  }, [isAddressCopied]);

  useEffect(() => {
    if (active && chainId !== globalVariable.POLYGON_CHAINID) {
      setIsWrongNetworkModalVisible(true);
      switchNetwork(ethereum, null, null);
    }
  }, [active, chainId]);

  useEffect(() => {
    const onChainChanged = (chainId: string) => {
      if (chainId === globalVariable.POLYGON_CHAINID_HEX) setIsWrongNetworkModalVisible(false);
      else setIsWrongNetworkModalVisible(true);
    };

    const onAccountChanged = (accounts: string[]) => {
      if (!accounts.length) {
        localStorage.removeItem(StorageKeys.WALLET_CONNECTED);
        disconnectMetamask();
      }
    };
    if (ethereum && active) {
      ethereum.on('chainChanged', onChainChanged);
      ethereum.on('accountsChanged', onAccountChanged);
    }
    return () => {
      if (ethereum) {
        ethereum.removeListener('chainChanged', onChainChanged);
        ethereum.removeListener('accountsChanged', onAccountChanged);
      }
    };
  }, [ethereum, active]);

  useEffect(() => {
    const saveAddress = async () => {
      localStorage.setItem(StorageKeys.WALLET_CONNECTED, account as string);
      try {
        await CampaignNftService.connectWallet({ wallet_address: account as string });
      } catch (error) {
        // Do nothing
      }
    };
    if (account && chainId === globalVariable.POLYGON_CHAINID) {
      saveAddress();
    }
  }, [account, chainId]);

  useEffect(() => {
    const connectedAccount = localStorage.getItem(StorageKeys.WALLET_CONNECTED);
    if (connectedAccount && !active) connectInjected(null, null, null);
  }, [active]);

  return (
    <LayoutDashboardStyled id={theme.layout.layoutId} className={className}>
      {isLoading > 0 && <LoadingPage isLoading={!!isLoading} />}
      {isBrowser && (
        <>
          <ModalWebsite />
          <CookiesConsent />
          <ModalCheckout type={PaymentModalType.CREATE_CAMPAIGN} />
          <ModalAddMailing />
          <AddMailingButtonModal />
          <ModalCreditBalance />
          <WarningThresholdLimitModal />
          <ModalPurchasePremium />
          <ModalPreviewLandingPage />
          <RedirectPastOrderModal />
          <ModalCreateLandingPage />
          <ModalSubscriptionPackage />
          <ModalConfigureBrokerSubscription />

          <CreditsModal
            type={PaymentModalType.MANUAL_BUY_CREDIT}
            cardPaymentInfo={userPaymentCard}
            onCancel={() => {
              setErrorPurchaseTemplate('');
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_CREDIT,
                payload: null,
              });
            }}
            onConfirm={(payload) => {
              buyManualCredit(payload);
            }}
            buyCreditError={errorPurchaseTemplate}
          />
          <HomeLimitModal
            title="Upgrade Home Limit"
            label="Current Home Limit"
            initialValue={mySub?.home_balance || 0}
            onSubmit={handleSubmit}
            minHome={minHome}
            visible={!!visibleGlobal && visibleGlobal === GlobalModalCredits.HOME_CREDITS}
            onCancel={() => {
              setErrorPurchaseTemplate('');
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_CREDIT,
                payload: null,
              });
            }}
            buyCreditError={errorPurchaseTemplate}
            isGlobal
          />
          <ConfirmModal
            className="confirm-address"
            title={'You can’t access this until you subscribe!'}
            btnSubmitText="Subscribe here"
            btnCancelText="Go Back"
            visible={visibleGlobalUnsubscribe}
            onCancel={() => {
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
                payload: false,
              });
              if (
                location?.pathname.includes(RoutePaths.CAMPAIGNS) ||
                location?.search?.includes(SmartActionTabsKey.SMART_ACTIONS) ||
                location?.pathname === RoutePaths.BROKER_MY_AGENTS
              )
                return;
              if (location?.search.includes('open_new_tab=true')) {
                window.close();
                return;
              }
              navigate(-1);
            }}
            onSubmit={() => {
              dispatch({
                type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
                payload: false,
              });
              navigate(`${RoutePaths.SETTINGS}/?tab=${SettingTabsKey.SUBSCRIPTION}`);
            }}
            closable={false}
            maskClosable={false}
            modalWidth={600}
          />
        </>
      )}
      {!isNotShowSidebar &&
        (!isMobile ? (
          <SideBar
            connectMetamask={onConnectMetamask}
            disconnectMetamask={disconnectMetamask}
            isAddressCopied={isAddressCopied}
            copyToClipBoard={copyToClipBoard}
          />
        ) : (
          <NavMobile
            connectMetamask={onConnectMetamask}
            disconnectMetamask={disconnectMetamask}
            isAddressCopied={isAddressCopied}
            copyToClipBoard={copyToClipBoard}
          />
        ))}
      <SimpleBar
        css={css`
          width: 100%;
          height: 100%;
          overflow-y: auto;
        `}
        ref={simpleBarRef}
        forceVisible={true}
        className="dashboard-content"
      >
        {' '}
        {children}
      </SimpleBar>
      <MetamaskNotFoundModal visible={isMetamaskNotFoundVisible} onCancel={toggleMetamaskNotFoundModal} />
      <LoadingModal
        visible={isWrongNetworkModalVisible}
        title="Wrong Network"
        content={
          <div
            css={css`
              .ant-btn {
                background-color: #04caae !important;
                border-color: #04caae !important;
                letter-spacing: initial;
                margin: auto;
                margin-top: 24px;
                font-weight: 700;
              }
            `}
          >
            <p>
              Please change network on your wallet to <b>Matic Mainnet</b> to continue.
            </p>
            <AppButtonV2 size="large" color="Pin" width="100%" onClick={() => switchNetwork(ethereum, null, null)}>
              Switch Network
            </AppButtonV2>
          </div>
        }
        processing
      />
      <LoadingModal
        visible={isConnectingWalletModalVisible}
        title="Connecting to Wallet"
        content={<p>Please complete the connection on your wallet and wait for us to process the connection...</p>}
      />
    </LayoutDashboardStyled>
  );
};

export default LayoutDashboard;
