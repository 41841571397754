import { css } from '@emotion/react';

export const useStyleModal = (): {
  styleModal;
} => {
  const styleModal = css`
    .ant-modal-root {
      .ant-modal-mask {
        background-color: #fff;
        opacity: 0.95;
      }
      .ant-modal {
        .ant-modal-content {
          background-color: #150056;
          border-radius: 15px;
          .ant-modal-body {
            text-align: center;
            color: #ffffff;
            h2 {
              font-family: FiraSans;
              font-size: 32px;
              font-weight: 700;
              color: #ffffff;
              margin-bottom: 30px;
            }
            p {
              font-size: 17px;
              font-weight: 400;
            }
            .ant-btn {
              margin: 0 auto;
              max-width: 100%;
              margin-top: 30px;
              margin-bottom: 30px;
              border-color: #04caae !important;
              height: 54px;
            }
            .install-metamask {
              padding: 0 32px;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -146px;
              background-color: #150056;
              border-radius: 15px;
              .btn-install {
                background-color: #04caae !important;
                color: #fff;
                display: flex;
                align-items: center;
                padding: 0px;
                letter-spacing: initial;
                span {
                  font-weight: 700;
                  font-size: 17px;
                  margin-left: 12px;
                }
                &:hover {
                  span {
                    letter-spacing: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  return { styleModal };
};
